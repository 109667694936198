import Api from "@aws-amplify/api"

const ApiService = (() => {
  const getYears = async () => {
    let data = { years: [] }
    const years = []
    try {
      data = await Api.get("GetItemList", "/medias/years")
      data.years.forEach(yearData => {
        years.push({
          year: +yearData.year.substr(-4, 4),
          bucket: yearData.year.substr(0, yearData.year.length - 5),
          image_ct: yearData.image_ct,
          video_ct: yearData.video_ct,
        })
      })
      return years
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const getYearDays = async year => {
    try {
      let data = await Api.get("GetItemList", `/medias/days?year=${year}`)
      const days = []
      data.days.forEach(dayData =>
        days.push({
          day: dayData.day.substr(-8, 8),
          bucket: dayData.day.substr(0, dayData.day.length - 9),
          image_ct: dayData.image_ct,
          video_ct: dayData.video_ct,
        })
      )
      return days
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const getDayMedias = async day => {
    try {
      const medias = []
      const data = await Api.get("GetItemList", `/medias?day=${day}`)
      data.medias.forEach((media, index) => {
        medias.push({
          id: `latest${index}`,
          key: media.key,
          height: media.height,
          width: media.width,
          size: media.size,
          mode: "CALENDAR-DAY"
        })
      })
      return medias
    } catch (error) {
      console.error(error)
      return
    }
  }

  const getAdminCheckData = async bucket => {
    try {
      return await Api.get("GetItemList", `/medias/admin?bucket=${bucket}`)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const reBuildTree = async bucket => {
    try {
      return await Api.get("GetItemList", `/medias/build?bucket=${bucket}`)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const markObject = async (bucket, key) => {
    try {
      return await Api.put("GetItemList", `/medias/mark?bucket=${bucket}&key=${key}`, { body: { }})
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const deleteObject = async (bucket, key) => {
    try{
      return await Api.del("GetItemList", `/medias?bucket=${bucket}&key=${key}`)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const getAlbums = async () => {
    try {
      let data = await Api.get("GetItemList", "/albums")
      // console.log('API Service - getAlbums - data', data)
      const albums = []
      data.albums.forEach(albumInfo => {
        albums.push({
          albumId: albumInfo.album,
          name: albumInfo.name,
          description: albumInfo.description,
          private: albumInfo.private,
          published: albumInfo.published,
          allow_edit: albumInfo.allow_edit,
          peoples: albumInfo.peoples,
          primeMedia: albumInfo.primeMedia,
          data: albumInfo.data,
          count: albumInfo.count
        })
      })
      return albums
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const getAlbumSortData = async (albumId) => {
    try {
      let data = await Api.get("GetItemList", `/albums/sort?alb=${window.encodeURIComponent(albumId)}`)
      let albumSortData = null
      if (data.albums.length === 1) {
        albumSortData = data.albums[0].sort
      }
      return albumSortData
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const deleteAlbum = async (albumId) => {
    try{
      return await Api.del("GetItemList", `/albums?key=${window.encodeURIComponent(albumId)}`)
    } catch (error) {
      console.error(error)
      return null
    } 
  }

  const getAlbumMedias = async (albumId) => {
    try {
      let data = await Api.get("GetItemList", `/albums/medias?alb=${window.encodeURIComponent(albumId)}`)
      const albumMedias = []
      data.medias.forEach(albumMedia => {
        albumMedias.push({
          albumId: albumMedia.alb,
          key: albumMedia.key,
          size: albumMedia.size,
          width: albumMedia.width,
          height: albumMedia.height
        })
      })
      return albumMedias
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const getDayAlbumMedias = async (day) => {
    try {
      let data = await Api.get("GetItemList", `/albums/day?day=${day}`)
      const albumMedias = []
      data.medias.forEach(albumMedia => {
        albumMedias.push({
          albumId: albumMedia.alb,
          key: albumMedia.key,
          size: albumMedia.size,
          width: albumMedia.width,
          height: albumMedia.height
        })
      })
      return albumMedias
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const sanitizeJSON = (string) => {
    return string.replace(/"/g, '\\"')
  }

  const upsertAlbumInfo = async (bodyData) => {
    // JSON is not sending properly - we need to replace 1st & last char by single quotes manullay
    try{
      const body = {
        alb: bodyData.albumId,
        name: bodyData.name,
        type: bodyData.type,
        description: bodyData.description,
        allow_edit: bodyData.allow_edit,
        published: bodyData.published,
        private: bodyData.private,
        prime_media: sanitizeJSON(bodyData.primeMedia),
        peoples: sanitizeJSON(bodyData.peoples),
        count: bodyData.count,
        data: sanitizeJSON(bodyData.data)
      }
      return await Api.post("GetItemList", `/albums`, { body })
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const upsertAlbumSortData = async (bodyData) => {
    // JSON is not sending properly - we need to replace 1st & last char by single quotes manullay
    try{
      const body = {
        alb: bodyData.albumId,
        sort: sanitizeJSON(bodyData.sort)
      }
      return await Api.post("GetItemList", `/albums/sort`, { body })
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const queueAlbumRebuild = async () => {
    try {
      return await Api.post("GetItemList", `/albums/finalise`, { body: { }})
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const bulkUpsertAlbumMediaInfo = async (dataList) => {
    try{
      if (!dataList.length) return
      const dataItems = dataList.map(item => ({
        alb_d: item.alb,
        day_d: +item.day,
        height_d: +item.height,
        key_d: item.key,
        size_d: +item.size,
        width_d: +item.width
      }))
      const body = { data: { PUT: dataItems } }
      await Api.post("GetItemList", `/albums/day`, { body })
      return await queueAlbumRebuild()
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const deleteAlbumMediaInfo = async (albumId, key) => {
    try{
      await Api.del("GetItemList", `/albums/medias`, { 
        body: {
          alb: albumId,
          key
          }
        })
      return await queueAlbumRebuild()
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const upsertAlbumMediaInfo = async (body) => {
    try{
      await Api.post("GetItemList", `/albums/medias`, { body })
      return await queueAlbumRebuild()
    } catch (error) {
      console.error(error)
      return null
    }
  }



  return {
    getYears,
    getYearDays,
    getDayMedias,
    getAdminCheckData,
    reBuildTree,
    markObject,
    deleteObject,
    getAlbums,
    getAlbumSortData,
    deleteAlbum,
    getAlbumMedias,
    getDayAlbumMedias,
    upsertAlbumInfo,
    upsertAlbumSortData,
    bulkUpsertAlbumMediaInfo,
    upsertAlbumMediaInfo,
    deleteAlbumMediaInfo
  }
})()

export default ApiService
