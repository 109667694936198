/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import BackToTop from './backToTop'

import Header from "./header"
import "./layout.css"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} menu={props.menu} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1536,
          padding: `0.75rem 1.0875rem 2rem`,
          position: 'relative'
        }}
      >
        <div className='versioning no-user-select'><span>v1.298</span></div>
        <main className="main-layout">{props.children}</main>
        <BackToTop />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
