const config = {
    Auth: {
        mandatorySignIn: false,
        region: 'eu-west-2',
        userPoolId: 'eu-west-2_kwgBeLehQ',
        userPoolWebClientId: '2fnbu9oiprd6vts8bmbdefpri6',
        identityPoolId: 'eu-west-2:cdd0946a-3b8b-43ea-bc3f-d56c52314067'
    },
    Storage: {
        region: 'eu-west-2',
        bucket: 'the-red-frog-cedric-alicia',
        identityPoolId: 'eu-west-2:cdd0946a-3b8b-43ea-bc3f-d56c52314067'
    },
    API: {
        endpoints: [
            {
                name: "GetItemList",
                endpoint: "https://api.the-red-frog.com"
            }
        ]
    }
  }
 
export { config }
    