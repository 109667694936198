const DisplayService = (() => {

    const toYYYYMMDD = (date) => {
        const yyyy = date.getFullYear().toString()
        const mm = (date.getMonth() + 101).toString().slice(-2)
        const dd = (date.getDate() + 100).toString().slice(-2)
        return yyyy + mm + dd
        }
  
      const fromYYYYMMDD = (dateStr) => {
        const y = dateStr.substr(0, 4)
        const m = dateStr.substr(4, 2) - 1
        const d = dateStr.substr(6, 2)
        return new Date(y, m, d)
      }

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr)
        return date.toLocaleDateString(window.userLang, {day: 'numeric', month: 'short', year: 'numeric'})
    }

    const getDateFromKey = (key) => {
        const split = key.split('/')
        const dateStr = split[split.length -1 ].substring(0, 8)
        const date = new Date()
        date.setFullYear(+dateStr.substr(0, 4), +dateStr.substr(4, 2) - 1, +dateStr.substr(6,2))
        return date
    }

    const getFormattedDateFromKey = (key) => {
        const date = getDateFromKey(key)
        return date.toLocaleDateString(window.userLang, {day: 'numeric', month: 'short', year: 'numeric'})
    }

    const getFormattedDayMonthFromString = (dateStr) => {
        const date = new Date()
        date.setFullYear(+dateStr.substr(0, 4), +dateStr.substr(4, 2) - 1, +dateStr.substr(6,2))
        return date.toLocaleDateString(window.userLang, {day: 'numeric', month: 'short'})
    }

    const displayMonthFromIndex = (monthIndex) => {
        const date = new Date()
        date.setDate(1)
        date.setHours(0,0,0,0)
        date.setMonth(monthIndex - 1)
        return date.toLocaleDateString(window.userLang, {month: 'short'})
    }

    return {
        toYYYYMMDD,
        fromYYYYMMDD,
        getDateFromKey,
        getFormattedDate,
        getFormattedDateFromKey,
        getFormattedDayMonthFromString,
        displayMonthFromIndex
    }
})()

export default DisplayService
