
import AppStore from './AppStore'
import Api from '@aws-amplify/api'

const CookieService = (() => {
    const set = async () => {
        if (window.location.protocol === 'http:') return

        const auth = AppStore.get('AUTH')
        if (!auth) {
            try {
                const data = await Api.get('GetItemList', `/auth`, { withCredentials: true })
                AppStore.set('AUTH', data)
            } catch (error) {
                console.error(error)
            }
        }
    }

    return {
        set
    }
})()

export default CookieService
