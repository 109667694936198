import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link } from "gatsby"
import UserService from '../services/UserService'
import menuIcon from '../images/menu/menu_burger.png'
import logoutIcon from '../images/menu/menu_logout.png'
import homeIcon from '../images/menu/menu_home.png'
import albumIcon from '../images/menu_album.png'
import calendarIcon from '../images/menu/menu_calendar.png'
import helpIcon from '../images/menu/menu_help.png'
import adminIcon from '../images/menu/menu_admin.png'
import peopleIcon from '../images/menu/menu_group.png'
import musicIcon from '../images/menu/menu_listen.png'
import './header.css'

class Header extends Component {

  constructor({ siteTitle }) {
    super()

    this.toggleMenu = this.toggleMenu.bind(this)

    this.state = {
      hasAdminAccess: false,
      isOpen: false
    }
  }

  signout = () => {
    UserService.signOut()
  }

  toggleMenu () {
    this.setState({
      isOpen : !this.state.isOpen
    })
  }

  async componentDidMount () {
    const userInfo = await UserService.getUserInfo()
    if (+userInfo.attributes['custom:admin'] > 0) {
      this.setState({
        hasAdminAccess: true
      })
    }
  }

  render () {
    const menuDefs = [
      { code: 'HOME', icon: homeIcon, txt: 'Accueil', path: '/' },
      { code: 'ALBUMS', icon: albumIcon, txt: 'Albums', path: '/albums' },
      { code: 'PEOPLE', icon: peopleIcon, txt: 'Participants', path: '/people' },
      { code: 'CALENDAR', icon: calendarIcon, txt: 'Calendrier', path: '/calendar' },
      { code: 'HELP', icon: helpIcon, txt: 'Tutos', path: '/help' }
    ]

    if (this.state.hasAdminAccess) {
      menuDefs.push({ code: 'MUSIC', icon: musicIcon, txt: 'Musique', path: '/music' })
      menuDefs.push({ code: 'ADMIN', icon: adminIcon, txt: 'Admin', path: '/admin' })
    }

    let currentIcon = ''
    let currentMenu = menuDefs.filter(menu => menu.code === this.props.menu)
    if (currentMenu.length) {
      currentIcon = <img src={currentMenu[0].icon} alt={currentMenu[0].txt} />
    } else {
      currentIcon = <img src={menuIcon} alt='Menu'  style={{ padding: '.75rem' }} />
    }

    let menuRender = []
    menuDefs.forEach(menu => {
      if (menu.code === this.props.menu) return
      menuRender.push(<Link exact="true" key={menu.code} to={menu.path} activeClassName="active" title={menu.txt}><img src={menu.icon} alt={menu.txt} /></Link>)
    })

    return (
      <header className={(this.state.isOpen) ? 'is-open' : ''}>
        <div className="outer">
          <div className="inner">
            <button onClick={this.toggleMenu} title={this.state.isOpen ? 'Fermer le menu' : 'Ouvrir le menu'}>{currentIcon}</button>
            { menuRender }
            <button onClick={this.signout} title="Sortir"><img src={logoutIcon} alt='log out!' /></button>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `The Red Frog`,
}

export default Header
