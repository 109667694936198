import AppStore from './AppStore'
import UserService from './UserService'

const BucketService = (() => {

    const getBucketData = () => {
        // TEMPORARY -> get from DB
        return [
            { name: 'the-red-frog-cedric-alicia', selected: true, display: 'Cédric & Alicia', displaySub: null },
            { name: 'the-red-frog-yannick', selected: true, display: 'Yannick', displaySub: 'Marcelo, Liliana' },
            { name: 'the-red-frog-marc-annie', selected: true, display: 'Marc & Annie', displaySub: null },
            { name: 'the-red-frog-patrice-bea', selected: true, display: 'Patrice & Bea', displaySub: '' },
            { name: 'the-red-frog-jeanluc-sonia', selected: true, display: 'Jean-Luc & Sonia', displaySub: null },
            { name: 'the-red-frog-pascal', selected: true, display: 'Pascal', displaySub: null },
            { name: 'the-red-frog-dominique', selected: true, display: 'Dominique', displaySub: null },
            { name: 'the-red-frog-marie', selected: true, display: 'Marie', displaySub: null }
        ]
    }

    const getList = async () => {
        let bucketList = AppStore.get('BUCKET_LIST')
        if (!bucketList) {
            bucketList = getBucketData()
            const userInfo = await UserService.getUserInfo()

            const sortMethod = (a, b) => {
                if (a.name === userInfo.attributes['custom:bucket-name']) return -1
                if (b.name === userInfo.attributes['custom:bucket-name']) return 1
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            }

            AppStore.set('BUCKET_LIST', bucketList.sort(sortMethod))
        }
        return bucketList
    }

    const setList = (bucketList) => {
        AppStore.set('BUCKET_LIST', bucketList)
    }

    const getAdminList = async () => {
        let bucketList = AppStore.get('BUCKET_ADMIN_LIST')
        if (!bucketList) {
            bucketList = getBucketData()
            const userInfo = await UserService.getUserInfo()

            bucketList = bucketList.filter(bucket => (
                +userInfo.attributes['custom:admin'] > 1 || 
                (+userInfo.attributes['custom:admin'] === 1 && userInfo.attributes['custom:bucket-name'] === bucket.name)))

            const sortMethod = (a, b) => {
                if (a.name === userInfo.attributes['custom:bucket-name']) return -1
                if (b.name === userInfo.attributes['custom:bucket-name']) return 1
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            }

            AppStore.set('BUCKET_ADMIN_LIST', bucketList.sort(sortMethod))
        }
        return bucketList
    }

    const getBucketInfo = (bucketName) => {
        const data = getBucketData()
        const filtered = data.filter(bucket => bucket.name === bucketName)
        if (filtered.length) return filtered[0]

        return {
            display: bucketName
        }
    }

    return {
        getList,
        setList,
        getAdminList,
        getBucketInfo
    }

})()

export default BucketService

