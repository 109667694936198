import React, { Component } from "react"
import backToTopIcon from "../images/back_to_top.svg"

class backToTop extends Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    if (
      document.documentElement.scrollTop >= window.innerHeight / 1.5 &&
      !this.state.display
    ) {
      this.setState({
        display: true,
      })
    }
    if (
      document.documentElement.scrollTop < window.innerHeight / 1.5 &&
      this.state.display
    ) {
      this.setState({
        display: false,
      })
    }
  }

  backToTopAction() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <button
        className={`back-to-top ${this.state.display ? "is-visible" : ""}`}
        onClick={this.backToTopAction}
      >
        <img src={backToTopIcon} />
      </button>
    )
  }
}

export default backToTop
