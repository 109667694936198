import AppStore from './AppStore'
import Auth from '@aws-amplify/auth';

const UserService = (() => {
    
    const getUserInfo = async () => {
        let userInfo = AppStore.get('USER_INFO')
        if (!userInfo) {
            userInfo = await Auth.currentUserInfo()
            AppStore.set('USER_INFO', userInfo)
        }
        return userInfo
    }

    const signOut = () => {
      AppStore.unset('USER_INFO')  
      
      Auth.signOut()
        .then(data => {
          // console.error(data)
        })
        .catch(err => {
          // console.error(err)
        });
        Auth.signOut({ global: true })
        .then(data => {
          // console.error(data)
        })
        .catch(err => {
          // console.error(err)
        });
    }

    return {
        getUserInfo,
        signOut
    }
})()

export default UserService
