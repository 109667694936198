const AppStore = (() => {
  const get = key => {
    if (typeof Storage === "undefined") return null
    try {
      const dataObj = JSON.parse(localStorage.getItem(key))
      if (!dataObj) return null
      if (dataObj.expiry && dataObj.expiry < new Date().getTime()) return null
      return dataObj.data
    } catch (e) {
      console.error()
      return null
    }
  }

  const set = (key, data, period) => {
    if (typeof Storage === "undefined") return
    period = period ? period : 7200000 // 2 hours
    const dataObj = {
      data,
      expiry: new Date().getTime() + period,
    }
    localStorage.setItem(key, JSON.stringify(dataObj))
  }

  const unset = key => {
    if (typeof Storage === "undefined") return
    localStorage.removeItem(key)
  }

  const clearMediaTree = () => {
    if (typeof Storage === "undefined") return
    Object.keys(localStorage).forEach(key => {
      if (key.indexOf('CALENDAR-DAYS-') === 0 || key.indexOf('CALENDAR-ITEMS-') === 0 || key === 'CALENDAR-YEARS' || key === 'LATEST' || key === 'CALENDAR-DAY-PAGE-TRACKING') {
        localStorage.removeItem(key)
      }
    })
  }

  const removeMediaFromAlbums = (media) => {
    if (typeof Storage === "undefined") return
    const selectedDay = media.split('/').reverse()[0].substr(0, 8)
    Object.keys(localStorage).forEach(key => {
      if (key.indexOf(`DAYALBUMITEMS_${selectedDay}`) === 0 || key.indexOf('ALBUMITEMS_') === 0) {
        localStorage.removeItem(key)
      }
    })
  }

  return {
    get,
    set,
    unset,
    clearMediaTree,
    removeMediaFromAlbums
  }
})()

export default AppStore
