import React, { Component } from "react"
import { Link } from "gatsby"
import DisplayService from '../services/DisplayService'
import videoPlayIcon from '../images/icon-play.svg'
import assetDeleteIcon from '../images/asset-delete.png'
import assetMarkIcon from '../images/asset-mark.png'
import calendarIcon from '../images/menu/menu_calendar.png'
import './image.css'

class Image extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: true,
      url: ''
    }

    this.handleItemAction = this.handleItemAction.bind(this)
  }

  async componentDidMount () {
    // console.log('componentDidMount', this.props.data)
    let url = ''
    if (this.props.data.key) {
      url = `//common.the-red-frog.com/public/thumb/${this.props.data.key.replace('public/', '')}`
      if (this.props.data.key.indexOf('/public/videos/') > -1) {
        url =  `//common.the-red-frog.com/public/thumb/${this.props.data.key.replace('public/', '').replace('.mp4', '.jpg').replace('.MP4', '.jpg')}`
      }
    }
    return this.setState({
      isLoading: false,
      url
    })
  }

  displayFileSize(bytes) {
    var thresh = 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = ['kB','MB','GB','TB','PB','EB','ZB','YB']
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
  }

  handleItemAction() {
    if (!this.props.selectedAlbums.length) return alert("Vous n'avez pas selectionne d'album")
    this.props.albumItemsAction({ ...this.props.data, albumSelection: !this.props.data.albumSelection })
  }

  render () {
    let aspectRatio =  this.props.data.height / this.props.data.width * 100
    const videoMode = (this.props.data.key && this.props.data.key.indexOf('/videos/') > -1)

    if (aspectRatio < 50) {
      aspectRatio = 50
    }
    if (aspectRatio > 100) {
      aspectRatio = 100
    }

    if (this.props.aspectRatio) aspectRatio = this.props.aspectRatio

    let style = {
      display: 'block',
      width: '100%',
      border: 'none',
      background: 'none',
      paddingBottom: `${aspectRatio}%`,
      backgroundSize: 'contain',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      position: 'relative'
    }
    if (!this.state.isLoading) {
      style.backgroundImage = `url(${encodeURI(this.state.url).replace('(', '\\(').replace(')', '\\)')})`
    }

    let videoMark = ''
    if (videoMode) {
      videoMark = <div className='video-mark'><img src={videoPlayIcon} alt="play video" /></div>
    }
    
    let content = ''
    let adminBtns = ''
    let albumSelector = ''
    
    if (this.props.data.mode === 'CALENDAR-DAY') {
      const split = this.props.data.key.split('/')
      if ((+this.props.user.attributes['custom:admin'] === 1 && this.props.user.attributes['custom:bucket-name'] === this.props.data.key.split('/')[0]) || +this.props.user.attributes['custom:admin'] === 2) {
        adminBtns = <div className='btns'>
            <button onClick={() => this.props.markAction(this.props.data.key)} data-itemkey={this.props.data.key} title="Marker"><img src={assetMarkIcon} alt="mark item" /></button>
            <span>{this.displayFileSize(this.props.data.size)}</span>
            <button onClick={() => this.props.deleteAction(this.props.data.key)} data-itemkey={this.props.data.key} title="Effacer"><img src={assetDeleteIcon} alt="delete item" /></button>
          </div>
      }
      if (this.props.albumToolIsOpen) {
        albumSelector = <div className='detail-selector'>
            <div className="checkbox-wrapper">
              <input type="checkbox" className="cst-checkbox"  checked={this.props.data.albumSelection} disabled={this.props.selectedAlbums.length ? false : true} readOnly />
              <label className={`cst-checkbox-label no-select ${ this.props.data.albumSelection ? 'selected' : ''}`} role="button" onClick={() => this.handleItemAction()}></label>
            </div>
          </div>
      }
      content = <div className='image-detail no-select with-icons'>
        <div style={{ width: '100%' }}>
          <div className='detail left'><span>Bucket:</span> {this.props.data.bucketName}</div>
          <div className='detail left'><span>File:</span> {split.slice(4).join('/')}</div>
          {albumSelector}
        </div>
      </div>
    }

    if (this.props.data.mode === 'LATEST') {
      content = <div className='image-detail no-select'>
        <div>
          <div>{DisplayService.getFormattedDateFromKey(this.props.data.key)}</div>
          <div className='detail'><span>Bucket:</span> {this.props.data.bucketName}</div>
        </div>
      </div>
    }

    if (this.props.data.mode === 'ALBUM') {
      if (this.props.deleteAction) {
        adminBtns = <div className='btns'>
            <Link exact="true" to={`/day/${DisplayService.toYYYYMMDD(DisplayService.getDateFromKey(this.props.data.key))}`} activeClassName="active" title="Visiter la page du calendrier"><img src={calendarIcon} alt='calendar icon' /></Link>
            <div className="no-select">{DisplayService.getFormattedDateFromKey(this.props.data.key)}</div>
            <button onClick={() => this.props.deleteAction(this.props.data.key)} data-itemkey={this.props.data.key} title="Enlever de l'album"><img src={assetDeleteIcon} alt="delete item" /></button>
          </div>
      } else {
        content = <div className='image-detail no-select'>
            <Link exact="true" to={`/day/${DisplayService.toYYYYMMDD(DisplayService.getDateFromKey(this.props.data.key))}`} activeClassName="active" title="Visiter la page du calendrier">
              <img src={calendarIcon} alt='calendar icon' />
            </Link>
            <span>{DisplayService.getFormattedDateFromKey(this.props.data.key)}</span>
          </div>
      }
    }
    
    if (this.props.handleImageClick) {
      return <>
        <button 
          onClick={this.props.handleImageClick}
          data-key={this.props.data.key}
          style={style}
        >
          {videoMark}
        </button>
        {content}
        {adminBtns}
      </>
    }

    return <>
      <div 
        data-key={this.props.data.key}
        style={style}
      >
        {videoMark}
      </div>
      {content}
      {adminBtns}
    </>

  }
}

export default Image
